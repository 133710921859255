import React from "react";

import { ParentWrapper } from "@shared/salesTool/components.styles";
import { DashboardLayout } from "@shared/dashboard";
import { useStore } from "@state/store";
import styled from "styled-components";
import UploadFile from "@shared/dashboard/uploadFile";
import { Container } from "@util/standard";
import { Loading } from "@global";
import Thumbnail from "@shared/dashboard/thumbnail";

export default function Dashboard() {
  const templateAvailable = useStore(state => state.templateAvailable);
  const { templates, available, returned } = templateAvailable;

  const Templates = () => {
    if (returned && !available) {
      return <div>No templates found for this ad copy</div>;
    }

    if (!available) {
      return (
        <Container
          width="50%"
          margin="150px auto"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          rowGap={10}
        >
          <Loading width="50px" height="50px" baseColor="navy" thickness={5} />
          <span>Loading templates...</span>
        </Container>
      );
    }

    return (
      <Wrapper>
        <h3>Select template</h3>
        <Grid className="template-scroll-inner">
          {templates?.map((template: any, index: number) => {
            return (
              <Thumbnail bordered key={index}>
                <img src={template.thumbnail} />
              </Thumbnail>
            );
          })}
        </Grid>
      </Wrapper>
    );
  };

  return (
    <DashboardLayout currentPath="dashboard">
      <ParentWrapper margin="100px auto">
        <h1>Personalise your calendar</h1>
        <b>
          Select a template, input the required information, and your ad will be automatically
          generated.
        </b>
        <b>Or upload your design in the canvas to have 100% custom calendar.</b>
        <UploadFile title="Upload your design" />
        <Templates />
      </ParentWrapper>
    </DashboardLayout>
  );
}

const Wrapper = styled.div`
  margin-top: 70px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 60px;
  grid-row-gap: 25px;
  height: 100%;
`;
